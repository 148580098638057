import React from 'react';
import logo from './unsaas-logo.svg';
import './App.css';
import HelloWorld from './components/hello';
import Dashboard from './components/Dashboard/Dashboard';
import Preferences from './components/Preferences/Preferences';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
 
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Simple usage based subscriptions. Go-adless coming soon!
        </p>
      </header>
    </div>
  );
}

export default App;
